.dashboard-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
  }
  .dashboard-content-container {
    margin: 1em;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow: auto;
  }
  .dashboard-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .dashboard-content-header h2 {
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-size: xx-large;
    font-weight: 700;
  }
  .dashboard-content-iconAdd a {
    text-decoration: none;
    color: black;
  }
  
  .dashboard-content-iconAdd a:hover {
    text-decoration: none;
    color: #2d83b5;
  }
  .dashboard-content-search {
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.009em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    border: 1px solid gray;
  }
  .dashboard-content-search:focus {
    outline: #2d83b5 auto 1px;
  }
  .dashboard-content-input {
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    border: 0px;
    box-sizing: content-box;
    background: none transparent;
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 100%;
    color: #000000;
    font-size: 0.75rem;
    padding: 0.625rem;
  }
  table {
    width: 100%;
    margin: auto;
  }
  th {
    text-align: center;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    color: #2d83b5;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.035em;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
  }
  tbody {
    display: table-row-group;
  }
  tr,
  thead {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
  }
  td {
    text-align: center;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
  }
  td div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .dashboard-content-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .dashboard-content-avatar-edit {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 20px;
    cursor: pointer;
  }
  td span {
    margin: 0px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: black;
    font-weight: 700;
  }
  .dashboard-content-icon {
    width: 20px;
    margin-right: 10px;
  }
  .dashboard-content-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .dashboard-content-footer .pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    background-color: #f0f2f5;
    cursor: pointer;
  }
  .dashboard-content-footer .active-pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    color: #ffffff;
    background-color: #2d83b5;
    cursor: pointer;
  }
  .empty-table {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    color: gray;
    font-weight: 700;
    border-color: #ffffff;
  }
  
  .pagination-list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 5px;
  }
  
  .pagination-list .pagination-link {
    text-decoration: none;
    color: black;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
  }
  
  .pagination-list .pagination-link:hover {
    background-color: #5ab1bb;
    color: #ffffff;
  }
  
  .pagination-list .is-current {
    background-color: #5ab1bb;
  }
  
  .fab {
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
  
  /* Style tambahan untuk membuat tampilan tombol yang mencolok */
  .fab-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .modal-content {
    background-color: #fff;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  