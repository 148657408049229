/* Dashboard Content */
.dashboard-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Mengganti "auto" dengan "hidden" untuk menghindari scroll vertikal di halaman utama */
  }
  
  /* Dashboard Content Container */
  .dashboard-content-container {
    margin: 1em;
    background-color: white;
    border: 1px solid #ddd; /* Menambahkan border */
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-x: auto; /* Mengganti "overflow: auto" dengan "overflow-x: auto" untuk mengatasi scroll horizontal */
  }
  
  /* Dashboard Content Header */
  .dashboard-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; /* Menambahkan align-items untuk vertikal tengah */
  }
  
  .dashboard-content-header h2 {
    color: black;
    font-size: 24px; /* Mengganti "xx-large" dengan ukuran font yang lebih besar */
    font-weight: 700;
  }
  
  /* Dashboard Content Search */
  .dashboard-content-search {
    display: flex;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 5px;
    padding: 8px;
  }
  
  .dashboard-content-input {
    flex: 1;
    border: 0;
    background: none;
    margin: 0;
    padding: 8px;
    font-size: 14px;
    color: #000;
    outline: none;
  }
  
  .dashboard-content-input::placeholder {
    color: gray;
  }
  
  /* Table */
  .table-responsive {
    max-width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse; /* Menambahkan border-collapse untuk tabel */
  }
  
  th {
    text-align: center;
    padding: 12px 24px;
    background: transparent;
    color: #2d83b5;
    font-size: 14px; /* Mengganti ukuran font */
    font-weight: 700;
    border-bottom: 1px solid #ddd; /* Menambahkan border bawah pada header */
  }
  
  td {
    text-align: center;
    padding: 12px 24px;
    background: transparent;
    font-size: 14px; /* Mengganti ukuran font */
    border-bottom: 1px solid #ddd; /* Menambahkan border bawah pada sel */
  }
  
  /* Pagination */
  .pagination-list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; /* Mengganti margin-bottom menjadi margin-top */
    font-size: 1.2rem;
    gap: 5px;
  }
  
  .pagination-list .pagination-link {
    text-decoration: none;
    color: black;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
  }
  
  .pagination-list .pagination-link:hover {
    background-color: #5ab1bb;
    color: #fff;
  }
  
  .pagination-list .is-current {
    background-color: #5ab1bb;
    color: #fff;
  }
  