.sidebar {
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  display: flex;
  overflow-x: auto;
  justify-content: center;
  position: sticky;
  background-image: linear-gradient(#015c92, #2d83b5, #89ccf6);
}
.sidebar-logo-container {
  display: "flex";
  align-items:center ;
}
.sidebar-logo-container img {
  width: 100%;
  padding: 2em;
}
.sidebar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-items {
  height: 100%;
  width: 100%;
}
.sidebar-item {
  padding: 1.5em;
  padding-left: 2em;
  display: flex;
  color: #ffffff;
  list-style: none;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.sidebar-item:hover {
  background-color: #004b77;
}
.sidebar-item-active {
  padding: 1.5em;
  padding-left: 2em;
  display: flex;
  color: #ffffff;
  list-style: none;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  background-color: #004b77;
  cursor: pointer;
}
.sidebar-item-icon {
  width: 20px;
}
.sidebar-item-label {
  font-size: small;
  font-weight: 600;
  margin-left: 15px;
}
/* Footer */
.sidebar-footer {
  padding: 1.5em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
