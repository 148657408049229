@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  --theme-primary: #749bc2;
  --theme-secondary: #4682a9;
  --theme-font-primary: #91c8e4;
  --theme-font-secondary: #f6f4eb;
}

